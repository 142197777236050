import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommentsModule } from '@app/comments/comments.module';
import { InputPage } from '@app/inputs/components/input-page/input-page.component';
import { MarkdownModule } from '@app/markdown/markdown.module';
import { SharedModule } from '@app/shared/shared.module';
import { TagsModule } from '@app/tags/tags.module';

import {
  DfButtonsModule,
  DfIconModule,
  DfLabelMarkerModule,
  DfSpinnerButtonModule,
  DfToggleSwitchModule,
} from '@lib/fresco';
import { InputCardComponent } from './components/input-card/input-card.component';
import { InputDetailsComponent } from './components/input-details/input-details.component';
import { InputSessionFormPartialComponent } from './input-session-form-partial/input-session-form-partial.component';
import { UserInputModalService } from './services/user-input-modal.service';
import { AddInputsTagsModalComponent } from '@app/inputs/components/input-details/add-inputs-tags-modal/add-inputs-tags-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InputPageWrapper } from './components/input-page-wrapper/input-page-wrapper.component';
import { ContentHostingModule } from '@app/content-hosting';
import { SharedEmbeddedLearningModule } from '@app/embedded-learning/shared-embedded-learning.module';
import { InputsSharedModule } from './inputs-shared.module';
import { SkeletonComponent } from '@app/skeleton/skeleton.component';

@NgModule({
  imports: [
    MarkdownModule,
    CommonModule,
    SharedModule,
    DfButtonsModule,
    DfIconModule,
    DfSpinnerButtonModule,
    DfToggleSwitchModule,
    TagsModule,
    CommentsModule,
    ContentHostingModule,
    SharedEmbeddedLearningModule,
    InputsSharedModule,
    DfLabelMarkerModule,
    // Standalone Components
    SkeletonComponent, // InputCardComponent
  ],
  exports: [
    AddInputsTagsModalComponent,
    InputCardComponent,
    InputSessionFormPartialComponent,
    InputPage,
    InputDetailsComponent,
  ],
  declarations: [
    AddInputsTagsModalComponent,
    InputDetailsComponent,
    InputSessionFormPartialComponent,
    InputCardComponent,
    InputDetailsComponent,
    InputPage,
    InputPageWrapper,
  ],
  providers: [UserInputModalService, NgbActiveModal],
})
export class InputsModule {}
