import { StoreState } from '@dg/shared-rsm';
import { BusinessRule } from './business-rules';

export interface AutomationsState extends StoreState {
  orgId: number;
  page: number;
  pageSize: number;
  totalResults: number;
  numPages: number;
  sort: string;
  isDescending: boolean;
  facets: any[];
  term: string;
}

export interface AutomationsComputedState {
  selectedAutomations: BusinessRule[];
  inMultiselectMode: boolean;
}

export interface AutomationsApi {
  changePage: (page: number) => void;
  changePageSize: (take: number) => void;
  changeSort: (orderBy: string) => void;
  selectAutomations: ($event: {
    items?: BusinessRule[];
    item?: BusinessRule;
    isSelected: boolean;
  }) => void;
  clearSelectedAutomations: () => void;
}

/**
 * ViewModel is a union type of State + Computed + API
 */
export type AutomationsViewModel = AutomationsState &
  AutomationsComputedState &
  AutomationsApi;

/**
 * Initialization for AutomationsState
 * @returns
 */
export function initState(): AutomationsState {
  return {
    orgId: undefined,
    page: 1,
    pageSize: 50,
    totalResults: undefined,
    numPages: undefined,
    sort: 'createDate',
    isDescending: true,
    facets: [],
    term: '',
  };
}

export type AutomationsStore = AutomationsState & {
  ids: string[];
  entities: Record<string, BusinessRule>;
};
