import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PathwayTrackingLinksComponent } from './pathway-tracking-links.component';
import { SharedModule } from '@app/shared/shared.module';
import { TrackingLinksTableModule } from '../tracking-links-table/tracking-links-table.module';
import { TrackingLinkService } from '../services/tracking-link.service';
import { BreadcrumbsComponent } from '@app/shared/components/breadcrumbs/breadcrumbs.component';

@NgModule({
  declarations: [PathwayTrackingLinksComponent],
  imports: [
    CommonModule,
    SharedModule,
    TrackingLinksTableModule,
    // Standalone Components
    BreadcrumbsComponent, // PathwayTrackingLinks
  ],
  providers: [TrackingLinkService],
})
export class PathwayTrackingLinksModule {}
