import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Breadcrumb, Breadcrumbs } from './breadcrumbs.component.model';
import { CommonModule } from '@angular/common';
import { DfIconModule } from '@lib/fresco';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'dgx-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  standalone: true,
  imports: [CommonModule, DfIconModule, RouterModule],
})
export class BreadcrumbsComponent implements OnInit {
  @Input() public breadcrumbs: Breadcrumbs;
  @Output()
  public breadcrumbClicked = new EventEmitter<{
    event: Event;
    crumb: Breadcrumb;
  }>();
  public i18n: { [key: string]: string } = {};

  constructor(private translate: TranslateService) {}

  public get showBreadcrumbs(): boolean {
    return !!this.breadcrumbs?.length;
  }

  public ngOnInit(): void {
    this.i18n.A11y_BreadcrumbNavType = this.translate.instant(
      'A11y_BreadcrumbNavType'
    );
  }

  public handleClick(event: Event, crumb: Breadcrumb) {
    this.breadcrumbClicked.emit({ event, crumb });
  }
}
