import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { InsightsModule } from '@app/insights/insights.module';
import { OrgSettingsService } from '@app/orgs/services/org-settings.service';
import { SharedModule } from '@app/shared/shared.module';
import { FtpSharedModule } from './../ftp/ftp-shared.module';
import { ConfirmMovePlanModalComponent } from './components/confirm-move-plan-modal/confirm-move-plan-modal.component';
import { GroupsSharedModule } from '@app/groups/groups-shared.module';
import { RecommendationsModule } from '@app/recommendations/recommendations.module';
import { AutofocusDirective } from '@app/shared/directives/autofocus.directive';

// components
import { EditVisibilityModalComponent } from './components/edit-visibility-modal/edit-visibility-modal.component';
import { EditVisibilityModalService } from './components/edit-visibility-modal/edit-visibility-modal.service';
import { OrgFaqEmptyComponent } from './components/org-faq-empty/org-faq-empty.component';
import { OrgFaqEntryComponent } from './components/org-faq-entry/org-faq-entry.component';
import { OrgFaqViewComponent } from './components/org-faq-view/org-faq-view.component';
import { OrgFaqComponent } from './components/org-faq/org-faq.component';
import { OrgGroupsHeaderComponent } from './components/org-groups-header/org-groups-header.component';
import { OrgGroupsComponent } from './components/org-groups/org-groups.component';
import { MemberCountsComponent } from './components/org-members/member-counts/member-counts.component';
import { OrgMembersHeaderComponent } from './components/org-members/org-members-header/org-members-header.component';
import { OrgMembersComponent } from './components/org-members/org-members.component';
import { OrgPlansComponent } from './components/org-plans/org-plans.component';
import { OrgReportingComponent } from './components/org-reporting/org-reporting.component';
import { OrgSecurityComponent } from './components/org-security/org-security.component';
import { SettingTileComponent } from './components/setting-tile/setting-tile.component';
import { OrgProvidersService } from './services/org-providers.service';
import { EditMemberModalComponent } from './components/edit-member-modal/edit-member-modal.component';
import { AddOrgCollaboratorsModalComponent } from './components/add-collaborators-modal/add-collaborators-modal.component';
import { SettingTileToggleJsonComponent } from './components/setting-tile-toggle-json/setting-tile-toggle-json.component';
import { SettingTileHeaderComponent } from './components/setting-tile-header/setting-tile-header.component';
import { SettingTileSaveFooterComponent } from './components/setting-tile-save-footer/setting-tile-save-footer.component';
import { SettingTileValidationMessageComponent } from './components/setting-tile-validation-message/setting-tile-validation-message.component';
import { OrgSettingsComponent } from './components/org-settings/org-settings.component';

// providers
import { OrgBulkUploadModalComponent } from './components/org-bulk-upload-modal/org-bulk-upload-modal.component';
import { UploaderModule } from '@app/uploader/uploader.module';
import { OrgBulkUploadSuccessModalComponent } from './components/org-bulk-upload-success-modal/org-bulk-upload-success-modal.component';
import { OrgInfoService } from './services/org-info.service';
import { OrgService } from './services/org.service';
import { DfFormlyModule } from '@lib/fresco';
import { AuthorsListComponent } from './components/authors-list/authors-list.component';
import { PlanLinkComponent } from './components/plan-link/plan-link.component';
import { OrgPlansModalService } from './services/org-plans-modal.service';
import { OrgPlansFacade } from './components/org-plans/rsm';
import { UserModule } from '@app/user/user.module';
import { ScrollService } from '@dg/shared-services';
import { MarkdownModule } from '@app/markdown/markdown.module';
import { SettingTileToggleTextComponent } from './components/setting-tile-toggle-text/setting-tile-toggle-text.component';
import { CommonModule } from '@angular/common';
import { SkeletonComponent } from '@app/skeleton/skeleton.component';

/**
 * Shared module for Manage Org Component and Services
 *
 * NOTE: this module is eager loaded through the root AppModule to ensure that these providers
 * will exist on the root injector for lazily downgraded components.
 */
@NgModule({
  declarations: [
    EditVisibilityModalComponent,
    OrgSettingsComponent,
    // These components are also used in GAT pages (`/channel`)
    OrgReportingComponent,
    OrgSecurityComponent,
    SettingTileComponent,
    // These components are also used in the FAQ page (`/faq/:orgId`)
    OrgFaqEmptyComponent,
    OrgFaqEntryComponent,
    OrgFaqViewComponent,
    OrgFaqComponent,
    OrgMembersComponent,
    MemberCountsComponent,
    OrgMembersHeaderComponent,
    OrgGroupsComponent,
    OrgGroupsHeaderComponent,
    ConfirmMovePlanModalComponent,
    OrgPlansComponent,
    OrgBulkUploadModalComponent,
    OrgBulkUploadSuccessModalComponent,
    AuthorsListComponent,
    PlanLinkComponent,
    EditMemberModalComponent,
    AddOrgCollaboratorsModalComponent,
    SettingTileToggleTextComponent,
    SettingTileToggleJsonComponent,
    SettingTileHeaderComponent,
    SettingTileSaveFooterComponent,
    SettingTileValidationMessageComponent,
  ],
  imports: [
    InsightsModule,
    SharedModule,
    FtpSharedModule,
    GroupsSharedModule,
    UploaderModule,
    UserModule,
    RecommendationsModule,
    DfFormlyModule.forRoot(),
    RouterModule,
    MarkdownModule,
    CommonModule,
    //standalone components
    AutofocusDirective,
    SkeletonComponent, // OrgFaqComponent, OrgSecurityComponent
  ],
  providers: [
    EditVisibilityModalService,
    OrgProvidersService,
    OrgSettingsService,
    OrgService,
    OrgInfoService,
    OrgPlansFacade,
    OrgPlansModalService,
    ScrollService,
  ],
  exports: [
    EditVisibilityModalComponent,
    OrgSettingsComponent,
    // Components
    OrgReportingComponent,
    OrgFaqViewComponent,
    OrgFaqComponent,
    OrgSecurityComponent,
    OrgMembersComponent,
    OrgGroupsComponent,
    SettingTileComponent,
    OrgPlansComponent,
    OrgBulkUploadModalComponent,
    OrgBulkUploadSuccessModalComponent,
    AuthorsListComponent,
    PlanLinkComponent,
    EditMemberModalComponent,
    AddOrgCollaboratorsModalComponent,
    SettingTileToggleTextComponent,
    SettingTileToggleJsonComponent,
  ],
})
export class OrgsSharedModule {}
