import { NgModule } from '@angular/core';

// modules
import { GlobalAddModule } from '@app/global-add/global-add.module';
import { MarkdownModule } from '@app/markdown/markdown.module';
import { NotificationSharedModule } from '@app/notifications/notification-shared.module';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared/shared.module';

// components
import { PageHeaderComponent } from './page-header/page-header.component';
import { PageMetaComponent } from './page-meta/page-meta.component';
import { ProductHeaderComponent } from './product-header/product-header.component';
import { ProductSwitcherComponent } from './product-switcher/product-switcher.component';
import { UtilityBarComponent } from './utility-bar/utility-bar.component';
import { BannerComponent } from './banner/banner.component';
import { SearchSharedModule } from '@app/search/search-shared.module';
import { HeroImageComponent } from './hero-image/hero-image.component';
import { AppHeader } from '@app/shared/components/app-header/app-header.component';
import { AnalyticsGuard } from '@app/analytics/guards/analytics.guard';
import { SelectComponent } from '@app/shared/components/select/select.component';

@NgModule({
  declarations: [
    AppHeader,
    BannerComponent,
    HeroImageComponent,
    PageHeaderComponent,
    PageMetaComponent,
    ProductHeaderComponent,
    ProductSwitcherComponent,
    UtilityBarComponent,
  ],
  exports: [
    AppHeader,
    BannerComponent,
    HeroImageComponent,
    PageHeaderComponent,
    PageMetaComponent,
    ProductHeaderComponent,
    ProductSwitcherComponent,
    UtilityBarComponent,
  ],
  imports: [
    SharedModule,
    MarkdownModule,
    SearchSharedModule,
    NotificationSharedModule,
    GlobalAddModule,
    RouterModule,

    // Standalone components
    SelectComponent, // ProductHeaderComponent
  ],
  providers: [AnalyticsGuard],
})
export class LayoutModule_v1 {}

export type LayoutModule = LayoutModule_v1;
