import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ChartsSharedModule } from '@app/charts/charts-shared.module';
import { ProfileInsightsSharedModule } from '@app/profile/profile-insights-shared.module';
import { RecommendationsModule } from '@app/recommendations/recommendations.module';
import { SharedModule } from '@app/shared/shared.module';
import { TagsModule } from '@app/tags/tags.module';
import { ActivityInsightsComponent } from './activity-insights/activity-insights.component';
import { ColumnChartComponent } from './column-chart/column-chart.component';
import { ContentInsightsComponent } from './content-insights/content-insights.component';
import * as directives from './directives';
import { FlexSkillSectionsChartComponent } from './flex-skill-sections-chart/flex-skill-sections-chart.component';
import { GroupInsightsComponent } from './group-insights/group-insights.component';
import { KpiInsightsComponent } from './kpis/kpi-insights/kpi-insights.component';
import { KpiItemComponent } from './kpis/kpi-item/kpi-item.component';
import { KpiListComponent } from './kpis/kpi-list/kpi-list.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { OrgInsightsLearningSummaryDetailsComponent } from './org-insights-learning-summary-details/org-insights-learning-summary-details.component';
import { PathwayContentInsightsComponent } from './pathway-content-insights/pathway-content-insights.component';
import { PathwayEnrollmentSourceComponent } from './pathway-enrollment-source/pathway-enrollment-source.component';
import { PathwayInsightsComponent } from './pathway-insights/pathway-insights.component';
import { PathwayTrackingLinksModule } from './pathway-tracking-links/pathway-tracking-links.module';
import { PersonalInsightsComponent } from './personal-insights/personal-insights.component';
import { PersonalSkillsCareerInsightsComponent } from './personal-skills-career-insights/personal-skills-career-insights.component';
import { PersonalSkillsTableComponent } from './personal-skills-career-insights/personal-skills-table/personal-skills-table.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { SkillAndRolePlanTrackingLinksComponent } from './skill-and-role-plan-tracking-links/skill-and-role-plan-tracking-links.component';
import { TopDataChartListComponent } from './top-data-chart-list/top-data-chart-list.component';
import { TopGroupsComponent } from './top-groups/top-groups.component';
import { TrackingLinksTableModule } from './tracking-links-table/tracking-links-table.module';
import { UserCountWarningMessageComponent } from './user-count-warning-message/user-count-warning-message.component';
import { SelectComponent } from '@app/shared/components/select/select.component';
import { BreadcrumbsComponent } from '@app/shared/components/breadcrumbs/breadcrumbs.component';
import { SkeletonComponent } from '@app/skeleton/skeleton.component';

@NgModule({
  declarations: [
    ActivityInsightsComponent,
    KpiInsightsComponent,
    KpiItemComponent,
    KpiListComponent,
    PieChartComponent,
    TopDataChartListComponent,
    TopGroupsComponent,
    ColumnChartComponent,
    FlexSkillSectionsChartComponent,
    PathwayInsightsComponent,
    PersonalSkillsCareerInsightsComponent,
    PersonalSkillsTableComponent,
    LineChartComponent,
    ContentInsightsComponent,
    PersonalInsightsComponent,
    PathwayEnrollmentSourceComponent,
    PathwayContentInsightsComponent,
    OrgInsightsLearningSummaryDetailsComponent,
    SkillAndRolePlanTrackingLinksComponent,
    GroupInsightsComponent,
    UserCountWarningMessageComponent,
    directives.GroupFilterToggleDirective,
  ],
  exports: [
    ActivityInsightsComponent,
    KpiInsightsComponent,
    KpiItemComponent,
    KpiListComponent,
    PieChartComponent,
    ColumnChartComponent,
    TopDataChartListComponent,
    TopGroupsComponent,
    FlexSkillSectionsChartComponent,
    PathwayInsightsComponent,
    PersonalSkillsCareerInsightsComponent,
    ContentInsightsComponent,
    PathwayEnrollmentSourceComponent,
    OrgInsightsLearningSummaryDetailsComponent,
    SkillAndRolePlanTrackingLinksComponent,
    GroupInsightsComponent,
    UserCountWarningMessageComponent,
    directives.GroupFilterToggleDirective,
  ],
  imports: [
    ChartsSharedModule,
    PathwayTrackingLinksModule,
    ProfileInsightsSharedModule,
    RecommendationsModule,
    SharedModule,
    TagsModule,
    TrackingLinksTableModule,
    PathwayTrackingLinksModule,
    RouterModule,

    // Standalone Components
    SelectComponent, // OrgInsightsLearningSummaryDetailsComponent
    BreadcrumbsComponent, // OrgInsightsLearningSummaryDetails, PathwayContentInsights, PathwayTrackingLinks
    SkeletonComponent, // ActivityInsightsComponent, ContentInsightsComponent, KPIListComponent, LineChartComponent, PathwayEnrollmentSourceComponent, PersonalSkillsTableComponent, PieChartComponent, TopDataChartListComponent
  ],
  providers: [],
})
export class InsightsModule {}
