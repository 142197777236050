import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddToPathwayComponent } from './components/add-to-pathway/add-to-pathway.component';
import { ApolloAngularModule } from '@degreed/apollo-angular';
import { ModalModule } from '@app/shared/components/modal/modal.module';
import { DfSpinnerButtonModule } from '@lib/fresco';
import { SharedModule } from '@app/shared/shared.module';
import { SkeletonComponent } from '@app/skeleton/skeleton.component';

@NgModule({
  imports: [
    ApolloAngularModule,
    CommonModule,
    ModalModule,
    DfSpinnerButtonModule,
    SharedModule,
    // Standalone Components
    SkeletonComponent, // AddToPathwayComponent
  ],
  declarations: [AddToPathwayComponent],
  exports: [AddToPathwayComponent],
  providers: [],
})
export class PathwayModule {}
