import { inject, Injectable, InjectionToken, Injector } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { LiveAnnouncer } from '@angular/cdk/a11y';

import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  TOAST_DATA,
  PROGRESS_TOAST_DATA,
  ToastOptions,
  ProgressToastOptions,
  initToastOptions,
  initProgressToastOptions,
  ProgressToastEvent,
  ToastComponent,
  ProgressToastComponent,
} from '../components';

export type ProgressUpdate = (message: string, percentage: number) => void;

export const AUTO_CLOSE_FALLBACK = 3000;

/**
 * Toast Service currently handles instances of toasts with delayed autoClose
 * features. This service provides a simple API to show Toast messages to the user.
 *
 * NOTE: Current design pattern is intended to only 1 toast can be shown at a time;
 * new toasts intentionally replace the old toast.  Discuss with UX before making
 * any changes to the pattern.
 */
@Injectable()
export class ToastService {
  private liveAnnouncer: LiveAnnouncer = inject(LiveAnnouncer);

  private overlayRef: OverlayRef | null = null;
  private overlay: Overlay = inject(Overlay);
  private toastBodyEl: HTMLElement;

  private durationTimeoutId: number;
  private autoCloseDelay = AUTO_CLOSE_FALLBACK;

  /**
   * Show Toast messages to the user
   */
  showToast(message: string, partialOptions?: Partial<ToastOptions>) {
    const options: ToastOptions = this.hookToastActions({
      ...initToastOptions(),
      ...partialOptions,
      message,
    });

    this.autoCloseDelay = options.autoCloseDelay ?? AUTO_CLOSE_FALLBACK;
    this.makeToast(options, ToastComponent, TOAST_DATA);
    this.startAutoClose(options.autoClose);

    if (message) {
      this.liveAnnouncer
        .announce(message, options.type === 'error' ? 'assertive' : 'polite')
        .then(() => {
          // cleanup after a bit of a timeout to prevent issue where
          // subsequent toasts are not read out if they are the same message
          // needs to be a bit long to ensure the initial announcement
          // actually reads out before it's cleared
          setTimeout(() => {
            this.liveAnnouncer.clear();
          }, 1000);
        });
    }
  }

  /**
   * Easily show a Toast with a progressbar.
   */
  showProgressToast(
    message: string,
    partialOptions?: Partial<ProgressToastOptions>
  ): ProgressUpdate {
    const progress = new Subject<ProgressToastEvent>();
    const updateProgress = (message: string, percentage: number) => {
      progress.next({ message, percentage });
    };
    const options: ProgressToastOptions = this.hookProgressActions({
      ...initProgressToastOptions(),
      ...partialOptions,
      message,
      progress$: progress.asObservable(),
    });

    this.autoCloseDelay = options.autoCloseDelay ?? AUTO_CLOSE_FALLBACK;
    this.makeToast(options, ProgressToastComponent, PROGRESS_TOAST_DATA);
    this.startAutoClose();

    return updateProgress;
  }

  // *******************************
  // Private Functions
  // *******************************

  /**
   * Make and show instance of Toast Component
   * dispose of existing toast if it exists
   */
  private makeToast(
    options: ToastOptions | ProgressToastOptions,
    component: ComponentType<ToastComponent | ProgressToastComponent>,
    token: InjectionToken<ToastOptions | ProgressToastOptions>
  ) {
    const [overlayRef, injectors] = this.buildPortalConfig(options, token);
    const toastPortal = new ComponentPortal(component, null, injectors);

    overlayRef.attach(toastPortal);

    // store the toast body element to use for timers and focus
    this.toastBodyEl = overlayRef.overlayElement.getElementsByClassName(
      'da-toast-body'
    )[0] as HTMLElement;

    // only focus the toast if it does not autoclose for ease of
    // use closing it for screenreaders and keyboard users
    if (!options.autoClose) {
      this.focusToast(this.toastBodyEl);
    } else {
      this.setupClosingTimers(this.toastBodyEl);
    }
  }

  /**
   * Sets up several event listeners to keep the toast open if the user either
   * mouses over the toast or focuses in on it for longer readability.  Only
   * applicable for autoclosing toasts, resets the countdown timer from when
   * the user moves focus off or mouses off the element.  User can also choose
   * to close the toast with the close button themselves.
   *
   * @param toastBodyEl
   */
  private setupClosingTimers(toastBodyEl: HTMLElement) {
    // stay open when mouse is over the container element
    toastBodyEl.addEventListener('mouseover', () => {
      // clear the countdown while the mouse is on the element
      this.startAutoClose(false);
    });

    // stay open if the focus is on the container element
    toastBodyEl.addEventListener('focusin', () => {
      // clear the countdown while the mouse is on the element
      this.startAutoClose(false);
    });

    // start timer to close when the mouse is no longer over the container element
    toastBodyEl.addEventListener('mouseout', () => {
      // restart the countdown once mouse has left the element
      this.startAutoClose(true);
    });

    // start timer to close when the focus leaves the container element
    toastBodyEl.addEventListener('focusout', () => {
      // restart the countdown once mouse has left the element
      this.startAutoClose(true);
    });
  }

  /**
   * Focuses on the body of the toast itself-- narrow focus so the ring
   * is visible and only around the toast itself.
   *
   * @param toastBodyEl
   */
  private focusToast(toastBodyEl: HTMLElement) {
    // tabindex is required so focus will work--
    // set to -1 so it's not actually in the tab order
    // next tab should focus the close button
    toastBodyEl.setAttribute('tabindex', '-1');
    toastBodyEl.focus();
  }

  /**
   * Build the overlay config and injector for the toast
   */
  private buildPortalConfig(
    options: ToastOptions | ProgressToastOptions,
    token: InjectionToken<ToastOptions | ProgressToastOptions>
  ): [OverlayRef, Injector] {
    // const positionStrategy = buildPositionStrategry(this.overlay);
    const config: OverlayConfig = {
      // positionStrategy,
      hasBackdrop: false,
      panelClass: [
        'tw-reset',
        'tw-pointer-events-none',
        'tw-fixed',
        'tw-inset-0',
        'tw-flex',
        'tw-items-end',
        'tw-px-4',
        'tw-py-6',
        'sm:tw-items-start',
        'sm:tw-p-6',
      ], // Adding wrapper classes for mobile positioning
    };
    const injector = Injector.create({
      providers: [
        {
          provide: token,
          useValue: {
            ...options,
          },
        },
      ],
    });

    this.overlayRef = this.makeOverlayRef(config);
    return [this.overlayRef, injector];
  }

  /**
   * Intercept the close and action callbacks to dismiss the toast.
   */
  private hookToastActions(options: ToastOptions): ToastOptions {
    const action = options.action
      ? () => {
          options.action();
          this.dismiss();
        }
      : null;
    const close = options.closeLabel // close Label required to use close callback
      ? () => {
          if (options.close) options.close();
          this.dismiss();
        }
      : null;

    return { ...options, action, close };
  }

  /**
   * Intercept the progress events to auto reset the autoClose timer
   */
  private hookProgressActions(
    options: ProgressToastOptions
  ): ProgressToastOptions {
    let lastMessage = '';
    const resetAutoClose = this.resetAutoClose.bind(this);
    const announceProgress = (e: ProgressToastEvent) => {
      const message = e.error || e.message;
      // TODO: probably can clean this up-- if the message is the same it doesn't get reannounced
      // anyway, but should verify that this actually announces updates. A little worried these
      // updates are going to overwrite each other.  Using an aria-live region might be better.
      if (message !== lastMessage) {
        this.liveAnnouncer.announce(message, e.error ? 'assertive' : 'polite');
        lastMessage = message;
      }
    };

    let progress$: Observable<ProgressToastEvent> | null = options.progress$
      ? options.progress$.pipe(tap(resetAutoClose), tap(announceProgress))
      : null;

    return { ...options, progress$ };
  }

  /**
   * Ensure that only 1 toast is visible at a time.
   * Dispose of current toast first if it exists.
   */
  private makeOverlayRef(config: OverlayConfig) {
    this.dismiss();
    return this.overlay.create(config);
  }

  /**
   * Dismiss current toast if it exists
   */
  private dismiss() {
    if (this.overlayRef) {
      this.startAutoClose(false);

      this.overlayRef.detach();
      this.overlayRef.dispose();

      this.overlayRef = null;
    }
  }

  // *******************************
  // AutoClose Functions
  // *******************************

  private resetAutoClose() {
    this.startAutoClose(false);
    this.startAutoClose(true);
  }

  private startAutoClose(enabled = true) {
    const stopCountdown = () => {
      clearInterval(this.durationTimeoutId);
      this.durationTimeoutId = 0;
    };
    const startCountdown = () => {
      this.durationTimeoutId = window.setTimeout(() => {
        this.dismiss();
      }, this.autoCloseDelay);
    };

    if (this.durationTimeoutId) stopCountdown();
    if (enabled) startCountdown();
  }
}

// ******************************
// Helper Functions
// ******************************

const buildPositionStrategry = (overlay: Overlay) => {
  return overlay.position().global().top('20px').right('20px');
};
