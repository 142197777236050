import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { MentoringSharedModule } from '@app/mentoring/mentoring-shared.module';
import { RouterModule } from '@angular/router'; // required for UserSettingsNavComponent, please don't remove
import { NavigationModule } from '@app/navigation/navigation.module';
import { DfInputsModule } from '@lib/fresco';

// Components
import { RemovableUserItemComponent } from './components/removable-user-item/removable-user-item.component';
import { SettingsFamilyAccountsComponent } from './components/settings/family/settings-family-accounts/settings-family-accounts.component';
import { SettingsFamilyEmptyComponent } from './components/settings/family/settings-family-empty/settings-family-empty.component';
import { SettingsFamilyViewComponent } from './components/settings/family/settings-family-view/settings-family-view.component';
import { UserSearchModalComponent } from './components/user-search-modal/user-search-modal.component';
import { UserSearchComponent } from './components/user-search/user-search.component';
import { UserListFormComponent } from './components/user-list-form/user-list-form.component';
import { InviteUserSearchComponent } from './components/invite-user-search/invite-user-search.component';
import { UserSettingsNavComponent } from './components/settings/user-settings-nav/user-settings-nav.component';
import { AutofocusDirective } from '@app/shared/directives/autofocus.directive';

// Services
import { UserService } from '@app/user/services/user.service';
import { SkeletonComponent } from '@app/skeleton/skeleton.component';

@NgModule({
  declarations: [
    UserSearchModalComponent,
    SettingsFamilyViewComponent,
    SettingsFamilyEmptyComponent,
    SettingsFamilyAccountsComponent,
    RemovableUserItemComponent,
    UserListFormComponent,
    InviteUserSearchComponent,
    UserSettingsNavComponent,
  ],
  imports: [
    SharedModule,
    MentoringSharedModule,
    RouterModule,
    NavigationModule,
    DfInputsModule,

    //standalone components
    AutofocusDirective,
    UserSearchComponent,
    SkeletonComponent, // SettingsFamilyAccountsComponent, SettingsFamilyViewComponent
  ],
  exports: [
    RemovableUserItemComponent,
    UserListFormComponent,
    UserSearchComponent,
    UserSearchModalComponent,
    InviteUserSearchComponent,
    // look at moving userSettingsNav to the profile-settings module, please don't remove the export
    UserSettingsNavComponent,
  ],
  providers: [UserService],
})
export class UserModule {}
