import { TranslateModule } from '@ngx-translate/core';
import { VisibilityComponent } from './visibility.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfIconModule, DfLabelMarkerModule } from '@lib/fresco';
import { DgxGroupSearchModule } from '@app/shared/components/group-search/groups-search.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DgxCopyLinkModule } from '@app/shared/components/copy-link/copy-link.module';
import { SelectComponent } from '../select/select.component';

@NgModule({
  imports: [
    CommonModule,
    DgxCopyLinkModule,
    DgxGroupSearchModule,
    DfIconModule,
    DfLabelMarkerModule,
    NgbTooltipModule,
    TranslateModule,

    // standalone components
    SelectComponent,
  ],
  providers: [],
  declarations: [VisibilityComponent],
  exports: [VisibilityComponent],
})
export class DgxVisibilityModule {}
