import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProfilePicModule } from '@app/shared/components/profile-pic/profile-pic.module';
import { UserSearchItem } from '@app/user/user-api.model';
import { DfIconModule } from '@lib/fresco';

@Component({
  selector: 'dgx-user-search-item',
  templateUrl: './user-search-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, DfIconModule, ProfilePicModule],
})
export class UserSearchItemComponent {
  @Input() public item: UserSearchItem;
}
