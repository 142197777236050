import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import { ColorService } from '@app/shared/services/color.service';
import { TranslateService } from '@ngx-translate/core';

/**
 * DGX Skeleton Component
 *
 * Loads skeleton while waiting for the user interface to load - should be used in most cases over spinners
 *
 * Example:
 * <dgx-skeleton type="table" [columns]="5" [rows]="10"></dgx-skeleton>
 * <dgx-skeleton type="kpi"></dgx-skeleton>
 */

@Component({
  selector: 'dgx-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss'],
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonComponent implements OnChanges, OnInit {
  @Input() public columns?: number;
  @Input() public layout?: string = 'basic';
  @Input() public rows?: number;
  @Input() public type: string;
  @Input() public isMobile: boolean = false;

  // local
  public colArray: any[];
  public gridColWidth = 12;
  public gridColWidthPercent = '12%';
  public layouts = ['basic', 'video', 'image'];
  public rowArray: any[];
  public skeletonColor: string;

  // translations
  public i18n = this.translate.instant(['Core_Loading']);

  public constructor(
    private colorService: ColorService,
    private translate: TranslateService
  ) {}

  public ngOnInit() {
    this.skeletonColor = '#d4d7d9'; // See PD-89991 for bug around 'ebony-a18-hue'  this.colorService.getColor('ebony-a18-hue');
  }

  public ngOnChanges() {
    if (this.layout === 'random') {
      this.layout = this.layouts[Math.floor(Math.random() * 2)];
    }
    if (this.type === 'table') {
      if (this.columns && !isNaN(this.columns)) {
        this.gridColWidth = Math.floor(12 / this.columns);
        this.gridColWidthPercent = `${100 / this.columns}%`;
        this.colArray = [];
        for (let i = 0; i < this.columns; i++) {
          this.colArray.push(i);
        }
      }
      if (this.rows && !isNaN(this.rows)) {
        this.rowArray = [];
        for (let i = 0; i < this.rows; i++) {
          this.rowArray.push(i);
        }
      }
    }
    if (this.type === 'list' || this.type === 'permission') {
      if (this.rows && !isNaN(this.rows)) {
        this.rowArray = [];
        for (let i = 0; i < this.rows; i++) {
          this.rowArray.push(i);
        }
      }

      if (this.columns && !isNaN(this.columns)) {
        this.colArray = [];
        for (let i = 0; i < this.columns; i++) {
          this.colArray.push(i);
        }
      }
    }
  }
}
