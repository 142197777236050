import { ModuleWithProviders, NgModule } from '@angular/core';

import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { DfFormFieldBuilder, DfFormlyModule } from '@lib/fresco';
import { DfInputsModule } from '@lib/fresco';
import { ConfigOption, FORMLY_CONFIG } from '@ngx-formly/core';

// modules
import { UploaderModule } from '@app/uploader/uploader.module';
import { SharedModule } from '@app/shared/shared.module';
import { OrgsSharedModule } from '@app/orgs/orgs-shared.module';
import { TagsModule } from '@app/tags/tags.module';

// components
import { UserImageFieldComponent } from './wrappers/user-image-field/user-image-field.component';
import { SelectFieldComponent } from './wrappers/select-field/select-field.component';
import { OrgInternalContentVisibilityFieldComponent } from './wrappers/org-internal-content-visibility-field/org-internal-content-visibility-field.component';
import { MonthPickerFieldComponent } from './wrappers/month-picker-field/month-picker-field.component';
import { TagsFieldComponent } from './wrappers/tags-field/tags-field.component';
import { InputSessionFieldComponent } from './wrappers/input-session-field/input-session-field.component';
import { InputsModule } from '@app/inputs/inputs.module';
import { ComboboxFieldComponent } from './wrappers/combobox-field/combobox-field.component';
import { OrgsInternalContentVisibilityModule } from '@app/orgs/components/orgs-internal-content-visibility/orgs-internal-content-visibility.module';
import { SelectComponent } from '@app/shared/components/select/select.component';

@NgModule({
  imports: [
    NgbDatepickerModule,
    DfInputsModule,
    DfFormlyModule.forRoot({
      extras: {
        checkExpressionOn: 'changeDetectionCheck',
      },
      types: [
        {
          name: UserImageFieldComponent.REGISTERED_FIELD_TYPE,
          component: UserImageFieldComponent,
          wrappers: ['default'],
        },
        {
          name: SelectFieldComponent.REGISTERED_FIELD_TYPE,
          component: SelectFieldComponent,
          wrappers: ['default'],
        },
        {
          name: ComboboxFieldComponent.REGISTERED_FIELD_TYPE,
          component: ComboboxFieldComponent,
          wrappers: ['default'],
        },
        {
          name: OrgInternalContentVisibilityFieldComponent.REGISTERED_FIELD_TYPE,
          component: OrgInternalContentVisibilityFieldComponent,
          wrappers: ['default'],
        },
        {
          name: MonthPickerFieldComponent.REGISTERED_FIELD_TYPE,
          component: MonthPickerFieldComponent,
          wrappers: ['default'],
        },
        {
          name: TagsFieldComponent.REGISTERED_FIELD_TYPE,
          component: TagsFieldComponent,
          wrappers: [], // don't wrap since tags field has its own special label tip
        },
        {
          name: InputSessionFieldComponent.REGISTERED_FIELD_TYPE,
          component: InputSessionFieldComponent,
          wrappers: [],
        },
      ],
    }),
    UploaderModule,
    SharedModule,
    // OrgsSharedModule,
    OrgsInternalContentVisibilityModule,
    TagsModule,
    InputsModule,

    // shared components
    SelectComponent, // SelectFieldComponent
  ],
  exports: [DfFormlyModule],
  declarations: [
    UserImageFieldComponent,
    SelectFieldComponent,
    ComboboxFieldComponent,
    OrgInternalContentVisibilityFieldComponent,
    MonthPickerFieldComponent,
    TagsFieldComponent,
    InputSessionFieldComponent,
  ],
})
export class FormFieldsModule {
  public static forRoot(
    customConfig: ConfigOption = {}
  ): ModuleWithProviders<FormFieldsModule> {
    return {
      ngModule: FormFieldsModule,
      providers: [
        DfFormFieldBuilder,
        // pass any additional configuration to formly which will get merged into DF_COMMON_FORMS_CONFIG and formly's default config
        { provide: FORMLY_CONFIG, useValue: customConfig, multi: true },
      ],
    };
  }
}
